import { defineStore } from 'pinia';

interface dialog {
  freeze: boolean
  globalDialog: {
    status: boolean
    title: string
    text: string | VNode
    func: Function | null
    close: Function | null
    btnText: string
    persistent: boolean
    onlyConfirm: boolean
  }
}

interface dialogPayload {
  title?: string
  text: string | VNode
  func?: Function | null
  close?: Function | null
  btnText?: string
  persistent?: boolean
  onlyConfirm?: boolean
}
export default defineStore('globalDialog', {
  state: () =>
    <dialog>{
      freeze: false, // 禁止修改 dialog 避免後蓋前(ex:登入逾時提示視窗)
      globalDialog: {
        status: false,
        title: '',
        text: '',
        func: null,
        close: null,
        btnText: '',
        persistent: false, // if true，只能點確定關閉(有必須觸發的事件)
        onlyConfirm: false, // if true 只顯示確認按鈕
      },
    },
  actions: {
    setDialogFreeze(payload: boolean) {
      this.freeze = payload;
    },
    showDialog(payload: dialogPayload) {
      if (!this.freeze) {
        this.globalDialog.status = true;
        this.globalDialog.title = payload.title !== undefined ? payload.title : '提示';
        this.globalDialog.text = payload.text;
        this.globalDialog.func = payload.func !== undefined ? payload.func : null;
        this.globalDialog.close = payload.close !== undefined ? payload.close : null;
        this.globalDialog.btnText = payload.btnText !== undefined ? payload.btnText : '確認';
        this.globalDialog.persistent = payload.persistent !== undefined ? payload.persistent : false;
        this.globalDialog.onlyConfirm = payload.onlyConfirm !== undefined ? payload.onlyConfirm : false;
      }
    },
    closeDialog() {
      this.globalDialog.status = false;
    },
  },
});

import { createPinia, defineStore } from 'pinia';

export default createPinia();

export interface loading {
  apiLoadingStatus: boolean
}
export interface redirect {
  isRedirect: boolean
}

export const useLoadingStore = defineStore('apiLoadingStatus', {
  state: () =>
    <loading>{
      apiLoadingStatus: false,
    },
  actions: {
    updateApiLoadingStatus(payload: boolean) {
      this.apiLoadingStatus = payload;
    },
  },
});

export const useRedirectStore = defineStore('isRedirect', {
  state: () =>
    <redirect>{
      isRedirect: false,
    },
  actions: {
    updateIsRedirect(payload: boolean) {
      this.isRedirect = payload;
    },
  },
});

export default function gtmInit() {
  const gtmId = import.meta.env.VITE_GTM_ID;
  const gtmScript = document.createElement('script');
  gtmScript.textContent = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');
  `;

  const gtmNoScript = document.createElement('noscript');
  gtmNoScript.textContent = `
    <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  `;

  document.head.appendChild(gtmScript);
  document.body.prepend(gtmNoScript);
}

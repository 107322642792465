<script lang="ts" setup>
// Basic
import { computed } from 'vue';

interface Props {
  value: boolean
  title?: string
  subtitle?: string
  size?: string
  width?: string
  class?: string
  submitDisabled?: boolean
  hasSubmit?: boolean
}
interface Emits {
  (e: 'update:value', value: boolean)
  (e: 'submit')
  (e: 'cancel')
}
const props = withDefaults(defineProps<Props>(), {
  title: '提示',
  subtitle: '',
  size: 'md',
  width: '',
  class: '',
  submitDisabled: false,
  hasSubmit: true,
});
const emits = defineEmits<Emits>();

const dialogSwitch = computed({
  get() {
    return props.value;
  },
  set(value) {
    emits('update:value', value);
  },
});
const dialogWidth = computed(() => {
  if (props.width)
    return props.width;

  if (props.size === 'sm')
    return '366px';

  if (props.size === 'md')
    return '700px';

  return '700px';
});

function handleCancel() {
  emits('update:value', false);
  emits('cancel');
}
function handleSubmit() {
  emits('submit');
}
</script>

<template>
  <el-dialog
    v-model="dialogSwitch"
    :class="`message-dialog message-dialog--${size} ${props.class}`"
    :title="props.title"
    :close-on-click-modal="false"
    center
    :append-to-body="true"
    :width="dialogWidth"
    data-cy="message-dialog"
  >
    <div v-if="subtitle.length" class="el-dialog__subtitle">
      {{ props.subtitle }}
    </div>
    <div class="el-dialog__content text-center">
      <slot />
    </div>
    <div class="el-dialog__actions justify-content-center">
      <el-button data-cy="message-dialog-cancel" :type="hasSubmit ? '' : 'primary'" @click="handleCancel">
        {{ hasSubmit ? '取消' : '關閉' }}
      </el-button>
      <el-button v-if="props.hasSubmit" type="primary" data-cy="message-dialog-submit" :disabled="props.submitDisabled" @click="handleSubmit">
        確定
      </el-button>
    </div>
  </el-dialog>
</template>

<style lang="scss">
@import '@/assets/scss/abstracts/variables',
  '@/assets/scss/abstracts/mixins';

.message-dialog {
  .el-dialog {
    &__body {
      padding: 20px 30px 30px;
      color: $content;
    }

    &__subtitle {
      padding-bottom: 30px;
      color: $content;
      text-align: center;
    }

    &__actions {
      margin-top: 30px;
    }
  }

  &--sm {
    .el-dialog {
      &__body {
        padding: 20px;
      }

      &__subtitle {
        padding: 10px 0;
      }
    }
  }
}
</style>

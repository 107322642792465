const data = [
  { name: 'CDP 教學影片' },
  { name: '總覽', authKey: 'm_a' },
  { name: '報表戰情室', authKey: 'm_b' },
  { name: '會員管理', authKey: 'm_d' },
  { name: '會員資訊', authKey: 'm_d' },
  { name: '標籤管理', authKey: 'm_e' },
  { name: '自訂受眾', authKey: 'f_01' },
  { name: '智慧名單', authKey: 'f_02' },
  { name: '受眾描述', authKey: 'm_f' },
  { name: '行銷自動化', authKey: 'g_01' },
  { name: '劇本 x 資料更新', authKey: 'g_01' },
  { name: '活動及劇本', authKey: 'g_01' },
  { name: '劇本 - 事件及節點', authKey: 'g_01' },
  { name: '劇本 - 流程舉例', authKey: 'g_01' },
  { name: '模板管理', authKey: 'g_02' },
  { name: '成效分析', authKey: 'g_03' },
  { name: '帳號管理', authKey: 'h_01' },
  { name: '行銷渠道管理', authKey: 'h_02' },
  { name: '發送設定', authKey: 'h_03' },
];

export default { data };

<script setup lang="ts">
import loading from 'vue3-loading-overlay';
import { useLoadingStore } from '@/stores/index';

export interface Props {
  active?: boolean
  zIndex?: number
  isFullPage?: boolean
  isInline?: boolean
  class?: string
  height?: number
  width?: number
}
const props = withDefaults(defineProps<Props>(), {
  active: undefined,
  zIndex: 10,
  isFullPage: true,
  isInline: true,
  class: '',
  height: 21,
  width: 21,
});

const loadingStore = useLoadingStore();

const active = computed(() => {
  return props.active === undefined ? loadingStore.apiLoadingStatus : props.active;
});

const defultClass = computed(() => {
  return props.isInline ? 'vld-overlay--inline' : '';
});
</script>

<template>
  <loading
    :class="props.class.concat(` ${defultClass}`)"
    loader="bars"
    color="#0FB53B"
    :active="active"
    :z-index="props.zIndex"
    :height="height"
    :width="width"
    data-cy="loading"
    :is-full-page="props.isFullPage"
  >
    <template #after>
      <slot v-if="$slots.after" name="after" />
      <span v-else>運算中</span>
    </template>
  </loading>
</template>

<style lang="scss">
@import "@/assets/scss/abstracts/variables";

.vld-overlay {
  &.table_loading {
    padding-right: 15px;

    .vld-background {
      background-color: transparent;
    }

    svg {
      width: 21px;
      height: 21px;
    }

    .vld-icon {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        margin-top: 0;
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }

  svg {
    width: 42px;
    height: 42px;
    fill: $primary;
    stroke: $primary;
  }

  .vld-icon {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-top: 10px;
      color: $primary;
      font-size: 16px;
    }
  }

  &.vld-overlay--inline {
    .vld-icon {
      flex-direction: row;

      span {
        margin-top: 0;
        margin-left: 4px;
      }
    }
  }
}
</style>

import Cookies from 'js-cookie';

export class SetCookie {
  constructor(public name: string) {}

  get() {
    const data = Cookies.get(this.name) || '';
    return data;
  }

  set(data: string) {
    Cookies.set(this.name, data);
  }

  remove() {
    Cookies.remove(this.name);
  }
}

export const LOGIN_TOKEN = new SetCookie('cdp_session');
export const CORP_TOKEN = new SetCookie('corp_session');

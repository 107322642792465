import { defineStore } from 'pinia';
import {
  API_GET_CORP_DYNAMIC_FIELD,
  API_GET_CORP_FIELD,
  API_GET_CORP_TOKEN,
  API_POST_LOGIN,
  API_TABLE_LAST_VERSION,
  WS_ACC_LOGOUT,
} from '@/plugins/api';
import { CORP_TOKEN, LOGIN_TOKEN } from '@/plugins/cookies';
import useDialogStore from '@/stores/dialog';
import { useLoadingStore, useRedirectStore } from '@/stores/index';
import type { loginAPI, loginInfo, loginStore } from '@/interface/Login';
import router from '@/router';
import searchCacheStore from '@/stores/searchCache';
import type { CorpFieldReq } from '@/interface/Global';
import resProcessor from '@/utils/resProcessor';

export default defineStore('loginStatus', {
  state: () =>
    <loginStore>{
      loginPopover: false,
      loginInfo: {
        user_id: '',
        name: '',
        email: '',
        role: '',
        corp: '',
        env: '',
        perms: [],
        apivar: '',
      },
      resetToken: '',
      perms: {
        // accountList: [
        //   '使用者列表檢視',
        // ],
        // accountHistoryList: [
        //   '使用者行為紀錄檢視',
        //   '使用者行為紀錄下載',
        // ],
        // marketingLine: [
        //   '服務設定-LINEOA設定檢視',
        //   '服務設定-LINEOA設定編輯',
        // ],
        sendSettings: [
          '發送設定檢視',
          '發送設定編輯',
        ],
        target: [
          '受眾管理檢視',
          '受眾管理編輯',
          '受眾管理下載',
        ],
        activity: [
          '活動管理檢視',
          '活動管理編輯',
          '活動管理審核',
        ],
        template: [
          '模板管理檢視',
          '模板管理編輯',
        ],
        analytics: [
          '發送分析檢視',
          '發送分析下載',
        ],
        analyticsSendList: [
          '發送名單檢視',
          '發送名單下載',
        ],
        memberList: [
          '會員列表檢視',
        ],
        memberInfo: [
          '會員資訊檢視',
          '會員資訊編輯',
          '會員資訊下載',
        ],
        overview: [
          '總覽檢視',
          '總覽下載',
        ],
        smartList: [
          '智慧名單檢視',
          '智慧名單下載',
        ],
        targetDashboard: [
          '受眾描述檢視',
          '受眾描述下載',
        ],
        reportLab: [
          '報表實驗室檢視',
          '報表實驗室下載',
        ],
        account: [
          '帳號管理檢視',
          '帳號管理編輯',
        ],
        marketChannel: [
          '行銷渠道管理檢視',
          '行銷渠道管理編輯',
        ],
      },
      corpField: {
        max_users_allow_count: 0,
        used_users_count: 0,
        max_target_allow_count: 0,
        used_target_count: 0,
        max_tag_allow_count: 0,
        used_tag_count: 0,
        max_report_allow_count: 0,
        used_report_count: 0,
      },
      logOutWebSocket: null,
      etlLastCompleteVersion: '',
      corpDynamicField: null,
    },
  actions: {
    connectLogOutWebSocket() {
      if (!this.logOutWebSocket) {
        this.logOutWebSocket = WS_ACC_LOGOUT(null, (res) => {
          const { email, token } = res;
          if (email === this.loginInfo.email && token === LOGIN_TOKEN.get()) {
            const dialog = useDialogStore();
            const loading = useLoadingStore();

            loading.updateApiLoadingStatus(false);
            dialog.showDialog({
              title: '提示',
              text: `您已被登出系統，因為帳號 <span class="text-primary">${email}</span> 已自其他地方登入。`,
              persistent: true,
              func: async () => {
                const redirect = useRedirectStore();
                const searchCache = searchCacheStore();

                this.setLogout();
                searchCache.clean();
                redirect.updateIsRedirect(true);
                await router.push({ name: 'login' });
                redirect.updateIsRedirect(false);
                dialog.closeDialog();
                dialog.setDialogFreeze(false);
                this.logOutWebSocket?.close();
              },
              btnText: '返回登入',
              onlyConfirm: true,
            });
            dialog.setDialogFreeze(true);
          }
        });
      }
    },
    setLoginPopover(val: boolean) {
      this.loginPopover = val;
    },
    setLogin(token: string) {
      LOGIN_TOKEN.set(token);
    },
    setLogindData(payload: loginInfo) {
      this.loginInfo = { ...payload };
    },
    setLogout() {
      this.setLoginPopover(false);
      LOGIN_TOKEN.remove();
      CORP_TOKEN.remove();
    },
    resetInfo() {
      this.loginInfo = {
        user_id: '',
        name: '',
        email: '',
        role: '',
        corp: '',
        env: '',
        perms: [],
        apivar: '',
      };
      this.corpField = {
        max_users_allow_count: 0,
        used_users_count: 0,
        max_target_allow_count: 0,
        used_target_count: 0,
        max_tag_allow_count: 0,
        used_tag_count: 0,
        max_report_allow_count: 0,
        used_report_count: 0,
      };
      this.corpDynamicField = null;
    },
    changetResetToken(payload: string) {
      this.resetToken = payload;
    },
    async login(payload: loginAPI) {
      const dialogStore = useDialogStore();

      const data = new FormData();
      data.append('username', payload.username);
      data.append('password', payload.password);
      if (['admin@reddoor.com.tw', 'workflow@reddoor.com.tw'].includes(payload.username)) {
        dialogStore.showDialog({
          title: '提示',
          text: '帳號或密碼錯誤',
        });
        return;
      }
      try {
        const response = await API_POST_LOGIN(data);
        if (response.status === 200) {
          await this.setLogin(response.data.access_token);
          const res_corp = await API_GET_CORP_TOKEN();
          CORP_TOKEN.set(res_corp.data.access_token);
          const searchCache = searchCacheStore();
          searchCache.clean();
        }

        return {
          code: response.status,
          message: response.statusText,
          data: response.data,
        };
      }
      catch (error: any) {
        if (error instanceof Error) {
          if (error.message === 'gomaintenance') {
            window.location.replace(`${window.location.origin}/gomaintenance.html`);
          }
          else {
            if (error.message !== '') {
              dialogStore.showDialog({
                title: '提示',
                text: error.message,
              });
            }
          }
        }
      }
    },
    setEtl(payload: number | string) {
      this.etlLastCompleteVersion = payload;
    },
    async getEtl() {
      try {
        const response = await API_TABLE_LAST_VERSION();
        if (response.status === 200) {
          const { src_version } = response.data.data;
          this.setEtl(src_version);
        }
      }
      catch (error) {
        if (error instanceof Error) {
          const dialogStore = useDialogStore();
          dialogStore.showDialog({
            title: '提示',
            text: error.message,
          });
        }
      }
    },
    async getCorpField(req: CorpFieldReq) {
      try {
        const res = await API_GET_CORP_FIELD(req);
        const {
          max_users_allow_count,
          used_users_count,
          max_target_allow_count,
          used_target_count,
          max_tag_allow_count,
          used_tag_count,
          max_report_allow_count,
          used_report_count,
        } = resProcessor(res);

        if (max_users_allow_count !== undefined)
          this.corpField.max_users_allow_count = max_users_allow_count;
        if (used_users_count !== undefined)
          this.corpField.used_users_count = used_users_count;
        if (max_target_allow_count !== undefined)
          this.corpField.max_target_allow_count = max_target_allow_count;
        if (used_target_count !== undefined)
          this.corpField.used_target_count = used_target_count;
        if (max_tag_allow_count !== undefined)
          this.corpField.max_tag_allow_count = max_tag_allow_count;
        if (used_tag_count !== undefined)
          this.corpField.used_tag_count = used_tag_count;
        if (max_report_allow_count !== undefined)
          this.corpField.max_report_allow_count = max_report_allow_count;
        if (used_report_count !== undefined)
          this.corpField.used_report_count = used_report_count;
      }
      catch (error) {
        if (error instanceof Error) {
          const dialogStore = useDialogStore();
          dialogStore.showDialog({
            title: '提示',
            text: error.message,
          });
        }
      }
    },
    async getCorpDynamicField() {
      try {
        const res = await API_GET_CORP_DYNAMIC_FIELD();
        const {
          line_oa_num,
          script_node_num,
          target_num,
          tag_num,
          menus,
          channels,
          components,
        } = resProcessor(res);

        if ([menus, channels, components].every(item => item !== undefined)) {
          this.corpDynamicField = {
            line_oa_num,
            script_node_num,
            target_num,
            tag_num,
            menus,
            channels,
            components,
          };
        }
      }
      catch (error) {
        if (error instanceof Error) {
          const dialogStore = useDialogStore();
          dialogStore.showDialog({
            title: '提示',
            text: error.message,
          });
        }
      }
    },
  },
});

import { routes } from '@/router';

interface Search {
  [key: string]: any
}

export default () => {
  const search = useStorage<Search>('search', {});

  function routesSearch() {
    const { pathname } = location;
    return routes.find(item => item.path === pathname);
  }

  function set<T = any>(data: T) {
    const route = routesSearch();
    if (route && route.name)
      search.value[route.name] = cloneDeep(toRaw(data));
  }

  function get<T = any>(): (T | undefined) {
    const route = routesSearch();
    if (route && route.name)
      return cloneDeep(toRaw(search.value[route.name]));
    else
      return undefined;
  }

  function clean() {
    search.value = {};
  }

  return {
    search,
    set,
    get,
    clean,
  };
};

import type { AxiosResponse } from 'axios';
import type { APIRes } from '@/interface/Global';

/** Response has Error processor - Basic */
export default <T = any>(res: AxiosResponse<APIRes<T>> | APIRes<T>, hasErrorCode: boolean = false) => {
  const { data, code, message } = 'code' in res ? res : res.data;
  if (+code !== 1)
    throw !hasErrorCode ? new Error(message) : new Error(`${code}: ${message}`);

  return data;
};

/** Response hasn't Error processor */
export function hasNotErrorProcessor<T = any>(res: AxiosResponse<APIRes<T>> | APIRes<T>): APIRes<T> {
  return 'code' in res ? res : res.data;
}

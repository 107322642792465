import { ref } from 'vue';
import { defineStore } from 'pinia';

interface sidemenu {
  isCollapse: boolean
  routerKey: string
}

interface routerPayload {
  index: string
}

export const useSidemenuStore = defineStore('globalSidemenu', () => {
  const globalSidemenu = ref<sidemenu>({
    isCollapse: true,
    routerKey: '/',
  });
  const openMenu = () => {
    globalSidemenu.value.isCollapse = false;
  };
  const collapseMenu = () => {
    globalSidemenu.value.isCollapse = true;
  };
  const routerChange = (payload: routerPayload) => {
    globalSidemenu.value.routerKey = payload.index;
  };
  return { globalSidemenu, openMenu, collapseMenu, routerChange };
});

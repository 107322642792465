import { createApp as createBaseApp } from 'vue';
import { createPinia } from 'pinia';
import VueFeather from 'vue-feather';
import VueLazyload from 'vue-lazyload';
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import iconClock from './components/Icons/Clock.vue';
import App from './App.vue';
import router from './router';
import ElementPlusCustom from './assets/js/element-plus.full.mjs';
import resProcessor from './utils/resProcessor';
import { API_GET_USER } from '@/plugins/api';
import { useRedirectStore } from '@/stores';
import useLogin from '@/stores/login';
import useDialog from '@/stores/dialog';
import searchCacheStore from '@/stores/searchCache';
import { routesAuthMap } from '@/router/index';

import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import 'element-plus/dist/index.css';
import './assets/scss/abstracts/element-variables.scss';
import './assets/scss/main.scss';
import 'virtual:uno.css';

;

const store = createPinia();
export function createApp(options, rootProps) {
  const app = createBaseApp(options, rootProps);
  app
    .use(ElementPlusCustom)
    .use(router)
    .use(store)
    .use(CkeditorPlugin)
    .use(VueLazyload, { lazyComponent: true })
    .component('iconClock', iconClock)
    .component((VueFeather as any).name, VueFeather);

  return app;
}

const app = createApp(App, {});
app.mount('#app');

router.beforeEach(async (to: any, from, next) => {
  if (['gomaintenance', 'error'].includes(to.name)) {
    next();
    return;
  }

  const login = useLogin();
  const redirect = useRedirectStore();
  const searchCache = searchCacheStore();
  const dialog = useDialog();

  try {
    const res = await API_GET_USER();
    const { status, data } = res;

    if (status !== 200) {
      throw new Error('error');
    }

    login.setLogindData({
      user_id: data.user_id,
      name: data.username,
      email: data.email,
      role: data.role_name,
      corp: data.corp,
      env: data.env,
      perms: data.perms,
      apivar: data.apivar,
    });
    login.connectLogOutWebSocket();

    await login.getEtl();
    await login.getCorpDynamicField();

    // 檢查導向的route是否有權限
    const checkMenuAuth = (menuAuth) => {
      const { enable, display } = to.meta.menuAuth.length === 1 ? menuAuth[to.meta.menuAuth[0]] : menuAuth[to.meta.menuAuth[0]].menus[to.meta.menuAuth[1]];
      return menuAuth ? enable && display : true;
    };
    const routeHasMenuAuth = (to.meta.menuAuth && login.corpDynamicField !== null) ? checkMenuAuth(login.corpDynamicField.menus) : true;

    if (!routeHasMenuAuth) {
      if (to.name === 'home') {
        // 如果總覽隱藏 導去第一個不隱藏的頁面([外部數據洞察]除外)
        const corpDynamicFieldMenus = toRaw(login.corpDynamicField ? login.corpDynamicField.menus : {});
        const redirectRouteName = Object.entries(corpDynamicFieldMenus).reduce((acc, item) => {
          if (acc.length || item[0] === 'm_c')
            return acc;

          const { enable, display, menus } = item[1] as any;
          if (!acc && isEmpty(menus) && enable && display)
            return item[0];

          const findKey = Object.entries(menus).reduce((subAcc, subItem) => {
            if (subAcc.length)
              return subAcc;

            const { enable: subEnable, display: subDisplay } = subItem[1] as any;
            if (!subAcc && subEnable && subDisplay)
              return subItem[0];

            return subAcc;
          }, '');

          return findKey || acc;
        }, '');

        if (redirectRouteName)
          router.replace({ name: routesAuthMap[redirectRouteName] });
        else
          router.replace({ name: 'error' });
      }
      else {
        router.replace({ name: 'error' });
      }
    }
    else if (['login', 'forgot'].includes(to.name)) {
      router.replace({ name: 'home' });
    }
    next();
  }
  catch {
    const exclude = ['login', 'forgot', 'verify_reset', 'reset'];
    if (!exclude.includes(to.name)) {
      await new Promise((resolve) => {
        dialog.showDialog({
          title: '提示',
          text: '尚未登入或登入逾期',
          persistent: true,
          func: async () => {
            login.setLogout();
            searchCache.clean();
            redirect.updateIsRedirect(true);
            await router.push({ name: 'login' });
            redirect.updateIsRedirect(false);
            dialog.closeDialog();
            dialog.setDialogFreeze(false);
            resolve(true);
          },
          btnText: '返回登入',
          onlyConfirm: true,
        });
      });
    }

    next();

    if (exclude.includes(to.name)) {
      setTimeout(() => {
        searchCache.clean();
        login.resetInfo();
      }, 100);
    }
  }
});
